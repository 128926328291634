import { createReducer, on } from "@ngrx/store";
import { PURCHASING_STATUS, PurchasingState } from "./purchasing.state";
import { PurchasingActions } from "./purchasing.actions";

export const initialPurchasingState: PurchasingState = {
    price: '0.00',
    status: PURCHASING_STATUS.none,
    userMessage: 'Remove ads forever?',
  };
  
  
  export const purchaseReducer = createReducer(
        initialPurchasingState,
      on(PurchasingActions.updateProductPrice, (state, { price }) => {
        //console.log('PurchasingActions.updateProductPrice reducer hit.', )
        return { ...state, price: price };
      }),
      on(PurchasingActions.updatePurchasingStatus, (state, { status }) => {
        //console.log('PurchasingActions.updatePurchasingStatus reducer hit. status: ', status)
  
        let message = 'Remove ads forever?';

        if (status === PURCHASING_STATUS.purchasing){
            message = 'Transaction processing...'
        }else if (status === PURCHASING_STATUS.restoring){
            message = 'Restoring purchase....'
        }else if (status === PURCHASING_STATUS.none){
            // check previous states
            if (state.status === PURCHASING_STATUS.restoring){
                message = 'Product successfully restored!';
            }else if (state.status === PURCHASING_STATUS.purchasing){
                message = 'Transaction completed. Thank you for your support!';
            }
        }else if (status === PURCHASING_STATUS.error){
            message = "We're sorry, transaction was unsuccessful. Please try again later.";
            status = PURCHASING_STATUS.none;
        }

        return { ...state, userMessage: message, status: status };
      })
    );