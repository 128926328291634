import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular';
import { StorageService } from './storage.service';
import {
  IAPProduct,
  InAppPurchase2,
} from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { BannerAd } from '@admob-plus/capacitor';
import { environment } from 'src/environments/environment';
import { SingletonService } from './shared/singleton.service';
import { Store } from '@ngrx/store';
import { PurchasingActions } from './state/purchasing/purchasing.actions';
import { PURCHASING_STATUS } from './state/purchasing/purchasing.state';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  private productId = 'emoji_flip_remove_ads';
  public static Instance: AppComponent;
  public banner: BannerAd;

  constructor(
    private ref: ChangeDetectorRef,
    private actionController: ActionSheetController,
    private platform: Platform,
    private singletonService: SingletonService,
    private store: Store,
    private storageService: StorageService, // this is to bootstrap the storage service
    private appStore: InAppPurchase2,
    private router: Router,
  ) {
  }
  ngOnInit(): void {
    const self = this;
    this.platform.ready().then(() => {
      //this.error = 'constructor ready.';
      //this.appStore.verbosity = this.appStore.DEBUG;
      // register app

      // FIX: https://forum.ionicframework.com/t/how-use-in-app-puchase-with-ionic-6-capacitor-4/230472/16
      if (environment.production && this.appStore) {
        this.appStore.register({
          id: this.productId,
          type: this.appStore.NON_CONSUMABLE,
        });
  
        this.appStore.error((err) => {
          //console.log(err);
          this.store.dispatch(
            PurchasingActions.updatePurchasingStatus({
              status: PURCHASING_STATUS.error,
            })
          );
        });
  
        this.appStore
          .when(this.productId)
          .approved(async (product: IAPProduct) => {
            await self.deleteAd();
            product.finish();
            this.store.dispatch(
              PurchasingActions.updatePurchasingStatus({
                status: PURCHASING_STATUS.none,
              })
            );
            //this.ref.detectChanges(); // TODO do i need this
          });
        this.appStore
          .when(this.productId)
          .cancelled(async (product: IAPProduct) => {
            this.store.dispatch(
              PurchasingActions.updatePurchasingStatus({
                status: PURCHASING_STATUS.none,
              })
            );
          });
        this.appStore.ready(async () => {
          //await alert('ready fire');
          //alert('Products: ' + JSON.stringify(this.appStore.products)); // list all products
          const product = this.appStore.get(this.productId);
          console.log('Product: ' + JSON.stringify(product));
          if (!product.owned) {
            console.log('Product NOT owned, should display.');
            await self.showAd();
          }
        });
        this.appStore.when(this.productId).updated((product: IAPProduct) => {
          //console.log('Updated' + JSON.stringify(product));
          this.store.dispatch(
            PurchasingActions.updateProductPrice({ price: product?.price })
          );
        });
        this.appStore.refresh();
      }
    });
  }

  async onMultiplayerClick(event: any) {
    const actionSheet = await this.actionController.create({
      header: '# of Players',
      cssClass: 'my-custom-class',
      buttons: [
        { text: 'Against others on this device', data: 2 },
        { text: 'Online Lobby', data: 3 },
      ],
    });
    await actionSheet.present();

    const { data } = await actionSheet.onDidDismiss<number>();
    switch (data) {
      case 2:
        this.playGame(0, 'single');
        break;
      case 3:
        this.router.navigate(['./online-lobby']);
        break;
      default:
        break;
    }
  }
  private async playGame(num: number, mode: string) {
    if (num > 0) {
      this.router.navigate(['./emoji-game', { players: num, mode: mode }]);
    }
  }
  async showAd() {
    if (!this.banner){
      const adUnitId = this.platform.is('ios')
      ? 'ca-app-pub-1478689760729428/5936254748'
      : 'ca-app-pub-1478689760729428/2710238175';
      this.banner = new BannerAd({
        adUnitId: adUnitId,
        position: 'bottom',
      });
      await this.banner.show();
    }
  }
  async deleteAd() {
    await this.banner.hide();
  }
}
