import { BannerAd } from '@admob-plus/capacitor';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SingletonService {
  
  constructor() { 

  }
}
