import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'daily-challenge',
    loadChildren: () =>
      import('./daily-challenge/daily-challenge.module').then((m) => m.DailyChallengePageModule),
  },
  {
    path: 'emoji-game',
    loadChildren: () =>
      import('./emoji-game/emoji-game.module').then((m) => m.EmojiGamePageModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsPageModule),
  },
  {
    path: 'stats',
    loadChildren: () => import('./stats/stats.module').then( m => m.StatsPageModule)
  },
  {
    path: 'rewards',
    loadChildren: () => import('./rewards/rewards.module').then( m => m.RewardsPageModule)
  },
  {
    path: 'online-lobby',
    loadChildren: () => import('./online-lobby/online-lobby.module').then( m => m.OnlineLobbyPageModule)
  },
  {
    path: 'daily-challenge',
    loadChildren: () => import('./daily-challenge/daily-challenge.module').then( m => m.DailyChallengePageModule)
  },
  // I think I don't need these
  {
    path: 'single-player',
    loadChildren: () => import('./single-player/single-player.module').then( m => m.SinglePlayerPageModule)
  },
  {
    path: 'multi-player',
    loadChildren: () => import('./multi-player/multi-player.module').then( m => m.MultiPlayerPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
