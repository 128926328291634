export interface PurchasingState {
    price: string;
    status: string;
    userMessage: string;
}

export class PURCHASING_STATUS {
    static none = 'none';
    static purchasing = 'purchasing';
    static restoring = 'restoring';
    static error = 'error';
}