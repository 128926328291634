import { createReducer, on } from '@ngrx/store';
import { OnlineState } from './online.state';
import { OnlineActions } from './online.actions';

export const initialOnlineState: OnlineState = {
  connected: false,
  playersOnline: 0,
  leaderboard: [],
};


export const onlineReducer = createReducer(
    initialOnlineState,
    on(OnlineActions.getOnlineState, (state) => {
      //console.log('OnlineActions.getOnlineState reducer hit. Connected: ', state.connected)

      return { ...state };
    }),
    on(OnlineActions.updateOnlineState, (state, { connected}) =>{
      //console.log('OnlineActions.updateOnlineState reducer hit. Connected: ', connected)
        return {
            ...state,
            connected: connected
        };
    }),
    on(OnlineActions.updateOnlinePlayers, (state, { numOfPlayers }) =>{
      //console.log('OnlineActions.updateOnlinePlayers reducer hit. Players: ', numOfPlayers)
        return {
            ...state,
            playersOnline: numOfPlayers
        };
    }),
    on(OnlineActions.updateLeaderboard, (state, { players }) =>{
      //console.log('OnlineActions.updateLeaderboard reducer hit. Players: ', players)
        return {
            ...state,
            leaderboard: [...players]
        };
    })
  );