import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }
  /**
   *
   */

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    //console.log('initizal storage');
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public async set(key: string, value: any) {
    this._storage?.set(key, value);
    //console.log(await this._storage.keys());
    //console.log(await this._storage.get(StorageKeys.userName));
  }

  public async getAsync(key: string) : Promise<any>{
    const val = await this._storage?.get(key);
    //console.log(val);
    return val;
  }

  public get(key: string) : Observable<any>{
    const val = from(this._storage?.get(key));
    //console.log(val);
    return val;
  }

  public async clear(){
    await this._storage.clear();
  }
}

export class StorageKeys{
  static emojiList: string = 'emojiList';
  static memoryRetentionPercentage: string = 'memoryRetention';
  static memoryIq: string = 'memoryIq';
  static numberOfEmojis: string = 'numberOfEmojis';
  static stats: string = 'stats';
  static user: string = 'user';
  static dailyChallengeStats: string = 'dailyChallenge';
}