import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { PlayerStats } from "src/app/shared/player-stats";

export const OnlineActions = createActionGroup({
    source: 'Online Actions',
    events: {
      'Get Online State': emptyProps(),
      'Update Leaderboard': props<{ players: PlayerStats[]}>(),
      'Update Online State': props<{ connected: boolean}>(),
      'Update Online Players': props<{ numOfPlayers: number}>(),
    },
  });